import React from 'react';
import * as T from '../../components/Typography';
import * as S from './style';
import Loading from '../../components/Loading';
import { DatePicker } from '../../components/Inputs';
import { Col, Row } from '../../components/Grid';
import Button from '../../components/Button';

import { preForm as validate } from '../../validation/schemas';
import {
  getAge,
  calculateEligibility,
  createFaValues,
  isCurrentSchoolInTempLocation,
  mapEligibilityValues,
  mapFaValuesToParams
} from './helpers';
import { useGetSchools } from '../../api-calls/salesforce.queries';
import { navRoutes } from '../../constants';
import { useFormData, useValidateFormData } from '../../hooks';
import { goToFormPage, goToTopPage } from '../../utils/goToPage';
import logo from '../../components/assets/smf_logo.png';

import SchoolSection from './SchoolSection';
import JobSection from './JobSection';
import { TrackGoogleAnalyticsEvent } from '../../utils/googleAnalytics';

const initialFormState = {
  dob: null,
  schoolDetails: {
    Id: null,
    Name: null,
    BillingAddress: {
      street: null,
      state: null,
      postalCode: null,
      city: null,
      country: null
    },
    Academic_Criteria_2023__c: null,
    Social_Criteria__c: null,
    Admissions_Policy_New__c: null
  },
  gradSchoolDetails: {
    Id: null,
    Name: null,
    BillingAddress: {
      street: null,
      state: null,
      postalCode: null,
      city: null,
      country: null
    },
    Academic_Criteria_2023__c: null,
    Social_Criteria__c: null,
    Admissions_Policy_New__c: null
  },
  parent1JobDetails: {},
  parent2JobDetails: {}
};

const trackGAPreFormSubmission = (data, reason) =>
  TrackGoogleAnalyticsEvent({
    category: `Pre-Form Submission`,
    eventName: typeof reason === 'string' && reason.length > 0 ? reason : 'N/A',
    data: {
      ...data,
      url: window.location.pathname + window.location.search
    }
  });

const PreForm = () => {
  const submitAttempt = React.useRef(false);
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const { formState, setFormData } = useFormData({ initialFormState });
  const {
    schoolDetails,
    gradSchoolDetails,
    dob,
    parent1JobDetails,
    parent2JobDetails
  } = formState;

  const { validateForm, validationErrors, finalSubmissionData } =
    useValidateFormData({
      formState,
      validateFn: validate,
      submitAttempt
    });

  function handleSuccessfulSubmission(faValues) {
    const faParams = new URLSearchParams(mapFaValuesToParams(faValues));
    const faUrl = `${
      process.env.REACT_APP_FA_URL_FIRST
    }?${faParams.toString()}`;

    setSubmitLoading(false);
    trackGAPreFormSubmission(faValues, 'eligible');
    return goToFormPage(faUrl);
  }

  function handleUnsuccessfulSubmission(faValues, reason) {
    setSubmitLoading(false);
    trackGAPreFormSubmission(faValues, reason);
    const unsuccessfulPage =
      reason === 'ineligible_age'
        ? navRoutes.WEBSITE.APP_UNSUCCESSFUL_AGE
        : reason === 'current_school_not_in_temp_location'
          ? navRoutes.WEBSITE.APP_UNSUCCESSFUL_NOT_IN_TEMP_LOCATION
          : navRoutes.WEBSITE.APP_UNSUCCESSFUL_SCHOOL;

    return goToTopPage(unsuccessfulPage);
  }

  const validateSchoolLocation = (_schoolDetails, faValues) => {
    const tempLocationCities =
      process.env.REACT_APP_TEMP_LOCATION_CITIES?.split(',').map((city) =>
        city.trim()
      );

    const schoolIsNotInTempLocation = !isCurrentSchoolInTempLocation({
      schoolDetails: _schoolDetails,
      cities: tempLocationCities
    });

    if (schoolIsNotInTempLocation) {
      handleUnsuccessfulSubmission(
        faValues,
        'current_school_not_in_temp_location'
      );
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    submitAttempt.current = true;

    if (!validateForm()) {
      setSubmitLoading(false);
      return;
    }

    const { current } = finalSubmissionData;

    const faValues = createFaValues({
      dob: current?.dob,
      schoolDetails: current?.schoolDetails,
      gradSchoolDetails: current?.gradSchoolDetails,
      parent1JobDetails: current?.parent1JobDetails,
      parent2JobDetails: current?.parent2JobDetails
    });

    if (process.env.REACT_APP_ENABLE_TEMP_LOCATION_CHECK === 'true') {
      const isSchoolLocationValid = validateSchoolLocation(
        current?.schoolDetails,
        faValues
      );

      if (!isSchoolLocationValid) {
        return;
      }
    }

    const eligibility = calculateEligibility(
      mapEligibilityValues({
        dob: current?.dob,
        schoolDetails: current?.schoolDetails,
        gradSchoolDetails: current?.gradSchoolDetails
      })
    );

    if (eligibility.eligible) {
      handleSuccessfulSubmission(faValues);
    } else {
      handleUnsuccessfulSubmission(faValues, eligibility.reason);
    }
  };

  const {
    data: schools,
    error: getSchoolsError,
    isLoading: getSchoolsLoading,
    isError
  } = useGetSchools();

  if (getSchoolsLoading || submitLoading) {
    return <Loading />;
  }

  if (isError) {
    return <T.P color="error">{getSchoolsError.message}</T.P>;
  }

  return (
    <S.Wrapper>
      <S.Header>
        <S.LogoWrapper>
          <a href={navRoutes.WEBSITE.APP_HOME}>
            <S.Logo src={logo} alt="SMF Logo" />
          </a>
        </S.LogoWrapper>
        <Col w={[4, 8, 8]}>
          <T.H1 color="white" size="xLarge">
            01 Get started
          </T.H1>
          <T.P mt={6} mb={6} color="white">
            Let’s get started! You’ll notice asterisks (*) throughout the form.
            These are required fields. All other fields are optional.
          </T.P>
        </Col>
      </S.Header>
      <Row>
        <S.Card>
          <T.H2 color="white">1. DATE OF BIRTH</T.H2>
          <Row>
            <Col w={[4, 6, 6]}>
              <DatePicker
                label="Select date *"
                placeholder="DD/MM/YYYY"
                value={dob}
                name="dateOfBirth"
                handleChange={(date) => setFormData({ dob: date })}
                error={validationErrors.dob}
                minDate={new Date('1995-01-01T23:00:00.000Z')}
                maxDate={new Date()}
              />
              {dob ? (
                <T.P color="white" weight="bold" mt={5}>
                  You are {getAge(dob)} years old.
                </T.P>
              ) : null}
            </Col>
          </Row>
        </S.Card>
      </Row>
      <Row mt={5}>
        <SchoolSection
          schools={schools}
          schoolDetails={schoolDetails}
          gradSchoolDetails={gradSchoolDetails}
          validationErrors={validationErrors}
          setFormData={setFormData}
          initialFormState={initialFormState}
        />
      </Row>

      <Row mt={5}>
        <JobSection
          validationErrors={validationErrors}
          setFormData={setFormData}
          initialFormState={initialFormState}
          parent1JobDetails={parent1JobDetails}
          parent2JobDetails={parent2JobDetails}
        />
      </Row>
      <Row>
        {validationErrors.hasError && (
          <Col w={[4, 12, 12]}>
            <T.P color="error" mb={3} weight="bold">
              Please fill all the required fields
            </T.P>
          </Col>
        )}
        <Col w={[4, 6, 6]} mt={5}>
          <Button
            dataTestId="submit-form"
            variant="primary"
            type="submit"
            loading={submitLoading}
            disabled={validationErrors.hasError}
            handleClick={handleSubmit}
          >
            Confirm
          </Button>
        </Col>
      </Row>
    </S.Wrapper>
  );
};

export default PreForm;
